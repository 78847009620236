import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import PlayButton from '../static/play--button.svg';

import BilaalCover from '../static/social-examples/bilaal-cover.png';
import BilaalVideo from '../static/social-examples/EWY_Bilaal_Social_Video.mp4';

import ShibonCover from '../static/social-examples/shibon-cover.png';
import ShibonVideo from '../static/social-examples/EWY_Shibon_Social_Video.mp4';

import ExamCover from '../static/social-examples/results-cover.png';
import ExamVideo from '../static/social-examples/Greta-Exam-Results-Day.mp4';

import VapeCover from '../static/social-examples/vaping-cover.png';
import VapeVideo from '../static/social-examples/MM_Greta_New-on-the-blog.mp4';

import TransportCover from '../static/social-examples/transport-cover.png';
import TransportVideo from '../static/social-examples/MM_Public-transport-anxiety.mp4';

import LeedsCover from '../static/social-examples/next-step-cover.png';
import LeedsVideo from '../static/social-examples/NHS-Recruit.mp4';

import SocialDdfCover from '../static/social-examples/social-ddf-cover.png';
import SocialDdf from '../static/social-examples/social-ddf.mp4';

import SocialLebcCover from '../static/social-examples/social-lebc-cover.png';
import SocialLebc from '../static/social-examples/social-lebc.mp4';

import SocialCandiaCover from '../static/social-examples/social-candia-cover.png';
import SocialCandia from '../static/social-examples/social-candia.mp4';

const SocialExamplePage = (props) => {
    const { data, errors } = props;

    const bilaalVideo = useRef();
    const playButtonB = useRef();

    const [isPlayingBV, setIsPlayingBV] = useState(false);

    const handlePlayB = e => {
        if(!isPlayingBV) {
            setIsPlayingBV(true);
            bilaalVideo.current.play();
        } else {
            setIsPlayingBV(false);
            bilaalVideo.current.pause();
        }
    }

    const shibonVideo = useRef();
    const playButtonS = useRef();

    const [isPlayingSV, setIsPlayingSV] = useState(false);

    const handlePlayS = e => {
        if(!isPlayingSV) {
            setIsPlayingSV(true);
            shibonVideo.current.play();
        } else {
            setIsPlayingSV(false);
            shibonVideo.current.pause();
        }
    }

    const examVideo = useRef();
    const playButtonE = useRef();

    const [isPlayingEV, setIsPlayingEV] = useState(false);

    const handlePlayE = e => {
        if(!isPlayingEV) {
            setIsPlayingEV(true);
            examVideo.current.play();
        } else {
            setIsPlayingEV(false);
            examVideo.current.pause();
        }
    }

    const vapeVideo = useRef();
    const playButtonV = useRef();

    const [isPlayingV, setIsPlayingV] = useState(false);

    const handlePlayV = e => {
        if(!isPlayingV) {
            setIsPlayingV(true);
            vapeVideo.current.play();
        } else {
            setIsPlayingV(false);
            vapeVideo.current.pause();
        }
    }

    const transportVideo = useRef();
    const playButtonT = useRef();

    const [isPlayingT, setIsPlayingT] = useState(false);

    const handlePlayT = e => {
        if(!isPlayingT) {
            setIsPlayingT(true);
            transportVideo.current.play();
        } else {
            setIsPlayingT(false);
            transportVideo.current.pause();
        }
    }

    const leedsVideo = useRef();
    const playButtonL = useRef();

    const [isPlayingL, setIsPlayingL] = useState(false);

    const handlePlayL = e => {
        if(!isPlayingL) {
            setIsPlayingL(true);
            leedsVideo.current.play();
        } else {
            setIsPlayingL(false);
            leedsVideo.current.pause();
        }
    }

    const ddfVideo = useRef();
    const playButtonDdf = useRef();

    const [isPlayingDdf, setIsPlayingDdf] = useState(false);

    const handlePlayDdf = e => {
        if(!isPlayingDdf) {
            setIsPlayingDdf(true);
            ddfVideo.current.play();
        } else {
            setIsPlayingDdf(false);
            ddfVideo.current.pause();
        }
    }

    const candiaVideo = useRef();
    const playButtonCandia = useRef();

    const [isPlayingCandia, setIsPlayingCandia] = useState(false);

    const handlePlayCandia = e => {
        if(!isPlayingCandia) {
            setIsPlayingCandia(true);
            candiaVideo.current.play();
        } else {
            setIsPlayingCandia(false);
            candiaVideo.current.pause();
        }
    }

    const lebcVideo = useRef();
    const playButtonLebc = useRef();

    const [isPlayingLebc, setIsPlayingLebc] = useState(false);

    const handlePlayLebc = e => {
        if(!isPlayingLebc) {
            setIsPlayingLebc(true);
            lebcVideo.current.play();
        } else {
            setIsPlayingLebc(false);
            lebcVideo.current.pause();
        }
    }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>
                <div className='aviva--page-banner-image showreel social-examples'>
                    <div className='video--wrapper'>
                        {isPlayingDdf ? null :
                            <div className='video--overlay' onClick={handlePlayDdf}>
                                <img src={PlayButton} ref={playButtonDdf} />
                            </div>
                        }
                        <video
                            ref={ddfVideo}
                            className="hero__media"
                            controls="false"
                            poster={SocialDdfCover}
                        >
                            <source src={SocialDdf} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingCandia ? null :
                            <div className='video--overlay' onClick={handlePlayCandia}>
                                <img src={PlayButton} ref={playButtonCandia} />
                            </div>
                        }
                        <video
                            ref={candiaVideo}
                            className="hero__media"
                            controls="false"
                            poster={SocialCandiaCover}
                        >
                            <source src={SocialCandia} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingLebc ? null :
                            <div className='video--overlay' onClick={handlePlayLebc}>
                                <img src={PlayButton} ref={playButtonLebc} />
                            </div>
                        }
                        <video
                            ref={lebcVideo}
                            className="hero__media"
                            controls="false"
                            poster={SocialLebcCover}
                        >
                            <source src={SocialLebc} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingBV ? null :
                            <div className='video--overlay' onClick={handlePlayB}>
                                <img src={PlayButton} ref={playButtonB} />
                            </div>
                        }
                        <video
                            ref={bilaalVideo}
                            className="hero__media"
                            controls="false"
                            poster={BilaalCover}
                        >
                            <source src={BilaalVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingSV ? null :
                            <div className='video--overlay' onClick={handlePlayS}>
                                <img src={PlayButton} ref={playButtonS} />
                            </div>
                        }
                        <video
                            ref={shibonVideo}
                            className="hero__media"
                            controls="false"
                            poster={ShibonCover}
                        >
                            <source src={ShibonVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingEV ? null :
                            <div className='video--overlay' onClick={handlePlayE}>
                                <img src={PlayButton} ref={playButtonE} />
                            </div>
                        }
                        <video
                            ref={examVideo}
                            className="hero__media"
                            controls="false"
                            poster={ExamCover}
                        >
                            <source src={ExamVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingV ? null :
                            <div className='video--overlay' onClick={handlePlayV}>
                                <img src={PlayButton} ref={playButtonV} />
                            </div>
                        }
                        <video
                            ref={vapeVideo}
                            className="hero__media"
                            controls="false"
                            poster={VapeCover}
                        >
                            <source src={VapeVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingT ? null :
                            <div className='video--overlay' onClick={handlePlayT}>
                                <img src={PlayButton} ref={playButtonT} />
                            </div>
                        }
                        <video
                            ref={transportVideo}
                            className="hero__media"
                            controls="false"
                            poster={TransportCover}
                        >
                            <source src={TransportVideo} type="video/mp4" />
                        </video>
                    </div>

                    <div className='video--wrapper'>
                        {isPlayingL ? null :
                            <div className='video--overlay' onClick={handlePlayL}>
                                <img src={PlayButton} ref={playButtonL} />
                            </div>
                        }
                        <video
                            ref={leedsVideo}
                            className="hero__media"
                            controls="false"
                            poster={LeedsCover}
                        >
                            <source src={LeedsVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </Container>
        </Layout>
    </>
  )
};

SocialExamplePage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default SocialExamplePage;
